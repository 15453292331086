const contentData = [
  {
    title: "Transforming coordinate systems",
    link: "crs-transformation",
    description:
      "You can tranform spatial data from one coordinate system to another."
  },
  {
    title: "Coming soon",
    link: "coming-soon",
    description:
      "We have ideas, it's cooking."
  }
];

export default contentData;
