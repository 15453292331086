import React, { useState } from "react";
import { Form, ButtonGroup, Button, Alert, FormGroup } from "reactstrap";
import Highlight from "../components/Highlight";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";

export const RandmonComponent = () => {
  const { audience } = getConfig();
  const apiOrigin = audience.replace(/\/$/, "");

  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
    upperCase: false
  });

  const {
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const callApi = async () => {
    try {
      setState({
        ...state,
        showResult: false
      });
      const token = await getAccessTokenSilently();

      const response = await fetch(`${apiOrigin}/random/guid?upper=${state.upperCase}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.json();

      setState({
        ...state,
        showResult: true,
        apiMessage: responseData,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  return (
    <>
      <div className="mb-5">
        {state.error === "consent_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleConsent)}
            >
              consent to get access to users api
            </a>
          </Alert>
        )}

        {state.error === "login_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleLoginAgain)}
            >
              log in again
            </a>
          </Alert>
        )}
        
        <h1>Random</h1>
        <p className="lead">
          Get a random <a href="https://en.wikipedia.org/wiki/Universally_unique_identifier" target="_blank" rel="noreferrer">UUID</a>.
        </p>

        <p>
          Here you can get a randomn Universally Unique Identifier (UUID), also called Globally Unique Identifier (GUID).
        </p>

        <Form>
          <FormGroup>
            <ButtonGroup>
              <Button color="primary" outline onClick={() => setState({...state, upperCase: false, })} active={!state.upperCase}>
                Lowercase
              </Button>
              <Button color="primary" outline onClick={() => setState({...state, upperCase: true, })} active={state.upperCase}>
                Uppercase
              </Button>
            </ButtonGroup>
          </FormGroup>
          <FormGroup>
            <Button
              color="primary"
              className="mt-5"
              onClick={callApi}
              disabled={!audience}
            >
              Get randmon GUID
            </Button>
          </FormGroup>
        </Form>
      </div>

      <div className="result-block-container">
        {state.showResult && (
          <div className="result-block" data-testid="api-result">
            <h6 className="muted">Result</h6>
            <Highlight>
              <span>{JSON.stringify(state.apiMessage, null, 2)}</span>
            </Highlight>
          </div>
        )}
      </div>
    </>
  );
};

export default withAuthenticationRequired(RandmonComponent, {
  onRedirecting: () => <Loading />,
});
