import React from "react";

import logo from "../assets/logo.png";

const Hero = () => (
  <div className="text-center hero my-5">
    <img className="mb-3 app-logo" src={logo} alt="Logo" width="120" />
    <h1 className="mb-4">Omnimion</h1>

    <p className="lead">
      Omnimion at your service.
    </p>
  </div>
);

export default Hero;
